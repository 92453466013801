import { parseUrl as parseUrlWithQuery, stringifyUrl } from 'query-string';
import { EXTERNAL_LINK_TEXT } from '../../../components/screen-reader-hidden-text';
import { fromMode } from '../../navigation/routing';
import { openLink } from '../../utils';
import { createHiddenElementWithText } from '../create-hidden-element';

const constructQueryParams = href => stringifyUrl({ url: '', query: parseUrlWithQuery(href).query });

const TARGET_BLANK = '_blank';

export const changeLinks =
  history =>
  (element, children = element.querySelectorAll('a')) => {
    const onClick =
      ({ target, href, pathname }) =>
      event => {
        event.preventDefault();

        const isExternal = target === TARGET_BLANK;

        openLink(
          isExternal
            ? href
            : {
                ...fromMode(pathname),
                ...(href.includes('?') && { search: constructQueryParams(href) }),
                ...(href.includes('#') && { hash: href.split('#')[1] })
              },
          history,
          isExternal
        );
      };

    children.forEach(child => {
      child.addEventListener('click', onClick(child));

      if (child.target === TARGET_BLANK) {
        const hiddenElement = createHiddenElementWithText({ text: EXTERNAL_LINK_TEXT, tagName: 'span' });
        child.insertAdjacentElement('beforeend', hiddenElement);
      }
    });

    return element;
  };
