import { branch, compose, defaultProps, lifecycle, renderComponent, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from '@packages/helpers/core/common';
import { generateError } from '../../../../../helpers/inputs';
import { inputSchema } from '../../../helpers/schemas';
import { pickProps } from '../../../../../helpers/hocs/pick-props';
import { Input as BasicInput, withInputLabel } from '../../../../../components/inputs/input';
import { Textarea as BasicTextarea, withTextareaLabel } from '../../../../../components/inputs/textarea';
import {
  withAdvancedInformer,
  withAnchor,
  withError,
  withExplainer
} from '../../../../../components/inputs/input-field';
import { dynamicValidation } from '../../../helpers/dynamic-validations';
import { omitProps } from '../../../../../helpers/hocs/omit-props';
import { withContainer, withSubmit } from '../input-field';
import { withPreSubmitButton } from '../pre-submit-button/with-pre-submit-button';

const TEXTAREA_CONSTRAINTS = ['textarea'];

const Input = compose(
  omitProps(['loading']),
  withInputLabel,
  withError,
  withExplainer,
  withAdvancedInformer
)(BasicInput);

const Textarea = compose(
  omitProps(TEXTAREA_CONSTRAINTS),
  withTextareaLabel,
  withError,
  withExplainer,
  withAdvancedInformer
)(BasicTextarea);

// TODO: Old validation, remove when new validation is tested
// eslint-disable-next-line no-unused-vars
const withValidation = compose(
  connect(state => ({ state })),
  withState('errorMessage', 'setError', ''),
  withState('schema', 'setSchema', null),
  withHandlers(({ onChange, onSubmit, onError }) => {
    const handlers = {};

    if (onChange) {
      const onChange =
        ({ schema, setError, onChange }) =>
        value => {
          const { errors } = schema.validateSync(value);

          onChange(value);

          const actualError = generateError(errors);

          onError && onError(actualError);
          setError(actualError);
        };

      handlers.onChange = onChange;
    }

    if (onSubmit) {
      const onSubmit =
        ({ schema, value, setError, onSubmit }) =>
        event => {
          const { errors } = schema.validateSync(value);

          const actualError = generateError(errors);

          onError && onError(actualError);
          setError(actualError);

          if (actualError) return;

          onSubmit(event);
        };

      handlers.onSubmit = onSubmit;
    }

    return handlers;
  }),
  lifecycle({
    componentDidMount() {
      const { setSchema, inputType, format, mask, match, additionalRules, state, isOptional } = this.props;

      const { max, min, errorMessages } = dynamicValidation(state, match, {
        max: this.props.max,
        min: this.props.min,
        errorMessages: this.props.errorMessages
      });

      //Applies validation to input
      const schema = inputSchema(inputType, { max, min, errorMessages, format, mask, additionalRules, isOptional });

      setSchema(schema);
    },
    componentDidUpdate() {
      const { submitError, errorMessage, setError, onError, value, schema } = this.props;

      if (!isNil(value)) {
        const { errors } = schema.validateSync(value);
        const actualError = generateError(errors);

        onError && onError(actualError);
      }

      if (submitError && !errorMessage) {
        onError && onError(submitError);
        setError(submitError);
      }
    }
  })
);

const DEFAULT_PROPS = {
  inputType: 'number'
};

const CONSTRAINTS = [
  'format',
  'label',
  'mask',
  'maskChar',
  'max',
  'maxLength',
  'min',
  'type',
  'value',
  'textarea',
  'onChange',
  'onSubmit',
  'loading',
  'disabled',
  'buttonLabel',
  'explainer',
  'isRequired',
  'placeholder',
  'isOptional',
  'advancedInformer'
];

const mapProps = ({ errorMessage }) => ({
  error: errorMessage
});

export const InputWithButton = compose(
  withRouter,
  defaultProps(DEFAULT_PROPS),
  pickProps(CONSTRAINTS, mapProps),
  withAnchor,
  withContainer,
  withSubmit,
  withPreSubmitButton,
  branch(({ textarea }) => textarea, renderComponent(Textarea))
)(Input);
