import { configuration } from './configuration';

const CONFIGURATION_GROUP_NAME = 'configuration';

export const getConfigByGroupName = async (groupName = CONFIGURATION_GROUP_NAME, introducerCode) => {
  const thenHandler = response => {
    if (!response.ok) {
      return {};
    }

    return response.json();
  };

  return await configuration.getDataDictionary({ type: groupName, introducerCode }).then(thenHandler);
};

export const setGlobalConfig = async (introducerCode = 'default') => {
  window.CONFIG = await getConfigByGroupName(CONFIGURATION_GROUP_NAME, introducerCode);
};
