import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withPropsOnChange } from 'recompose';
import { withLoader } from '../../components/template';
import { getGuidance } from '../../store/reducers/guidance';
import GuidanceTemplate from '../../templates/guidance';
import { withBarrier } from '../account/pages/barrier/barrier';
import { createKPIListItems, getGuidanceProps, isGuidanceExternal } from './helpers';

const GuidancePage = ({ activities, statuses, score, guidance, isExternal, brokerName, kpiItems, isHPG }) => {
  return (
    <GuidanceTemplate
      statuses={statuses}
      guidance={guidance}
      activities={activities}
      score={score}
      isExternal={isExternal}
      brokerName={brokerName}
      kpiItems={kpiItems}
      isHPG={isHPG}
    />
  );
};

const mapStateToProps = ({ guidance, userAttributes, statusData }, { match }) => {
  const selectedGuidance = guidance.normalizedList[match.params.slug];
  const loading = guidance.loading;
  const statuses = statusData?.parsedStatuses;

  return { guidance: selectedGuidance, loading, userAttributes, statuses };
};

export default compose(
  withBarrier,
  connect(mapStateToProps, { getGuidance }),
  lifecycle({
    async componentDidMount() {
      const {
        getGuidance,
        match: {
          params: { slug }
        },
        location: { search }
      } = this.props;
      const isExternal = isGuidanceExternal(search);

      await getGuidance({ slug, isExternal });
    }
  }),
  withLoader,
  branch(({ guidance }) => !guidance, renderNothing),
  withPropsOnChange(['guidance', 'userAttributes'], ({ guidance, userAttributes, location: { search } }) => {
    if (!ObjectShim.isEmpty(guidance)) {
      const { totalScores, score, activities, isHPG, isExternal, brokerName } = getGuidanceProps({
        guidance,
        userAttributes,
        search
      });
      const kpiItems = createKPIListItems({ totalScores, score, guidance, scoreVM: userAttributes.scoreVM });

      return { kpiItems, activities, isHPG, isExternal, brokerName, score };
    }
  })
)(GuidancePage);
